import React, {useEffect, useRef} from "react"
import {useTranslation} from "react-i18next"

import './modeling.component.less';
import * as $ from "jquery";

import {useI18next} from "gatsby-plugin-react-i18next";
import Seo from "../seo";
import software1jpg from "../../assets/img/software/software_1.jpg";
import PlumePoster from "../../assets/img/wtu/plume-poster.jpg";
import ForecastPoster from "../../assets/img/wtu/forecast-poster.jpg";
import ForecastModeling from "../../assets/video/forecast-modeling.mp4";
import PlumeModeling from "../../assets/video/plume-modeling.mp4";
import Rectangle from "../../assets/img/industry/rectangle.svg";

const ModelingComponent = () => {
    const {i18n, t} = useTranslation();
    const {originalPath} = useI18next();
    const videoSoftwareRef = useRef(undefined);

    useEffect(() => {
        videoSoftwareRef.current.defaultMuted = true;
    })

    useEffect(() => {
        $('a[href^="#"]').click(function () {
            let elementClick = $(this).attr("href");
            let destination = $(elementClick).offset().top - 100;
            $('html, body').animate({
                scrollTop: destination
            }, 450);
            return false;
        });
    });

    return (
        <div>
            <Seo description={t("metaDescriptionSmartCity")}
                 lang={i18n.language} title={t("titleSmartCity")}
            />
            <div className="software">
                <div className="wrapper_block wrapper_block__block wrapper_block__big_padding_top wrapper_block__block--top-padding wrapper_block__block--no-border modeling-block">
                    <div className="borders-block__col modeling-block__header">
                        <div className="title-and-img__title">
                            <div className="title-and-img__rectangle"/>
                            <div
                                dangerouslySetInnerHTML={{__html: t("cityScreenPlume")}}/>
                        </div>
                        <div className="plumes-columns">
                            <div className="borders-block__col-left mr-0">
                                <div className="title-and-img__description_plumes">
                                    <div className="title-and-img__description--2">
                                        <a href="/platform/">{t("cityAirPlatform")}</a>
                                         {t("cityAirModuleAndTrajectoryTool")}
                                    </div>
                                </div>
                            </div>
                            <div className="borders-block__col-right plumes-right_col">
                                <div className="title-and-img__description--2 title-and-img__description--3 borders-block__col--point"
                                    dangerouslySetInnerHTML={{__html: t("theModuleIsBasedOnAStochastic")}}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="wrapper_block wrapper_block__block wrapper_block__block--no-border wrapper_block__block--no-padding-bottom">
                    <div className="borders-block__col--subtitle-plumes-3">
                        <div dangerouslySetInnerHTML={{__html: t("cityScreenPlumeOptions")}}/>
                    </div>
                </div>
                <div
                    className="wrapper_block wrapper_block__block wrapper_block__block--no-border borders-block">
                    <div className="borders-block__col">
                        <div className="borders-block__col-left">
                            <div className="borders-block__col--block">
                                <div className="borders-block__col--number">1
                                </div>
                                <div
                                    className="borders-block__col--big-text borders-block__col--big-text--2">
                                    <div
                                        dangerouslySetInnerHTML={{__html: t("emissionSourceSetup")}}/>
                                </div>
                            </div>
                            <div className="borders-block__hr--horizontal"/>
                            <div className="borders-block__col--block">
                                <div className="borders-block__col--number">2
                                </div>
                                <div
                                    className="borders-block__col--big-text borders-block__col--big-text--2">
                                    <div
                                        dangerouslySetInnerHTML={{__html: t("plumeDispersionForecastFor")}}/>
                                </div>
                            </div>
                            <div className="borders-block__hr--horizontal"/>
                            <div className="borders-block__col--block">
                                <div className="borders-block__col--number">3
                                </div>
                                <div
                                    className="borders-block__col--big-text borders-block__col--big-text--2">
                                    <div
                                        dangerouslySetInnerHTML={{__html: t("controlPointsMappingInAccordance")}}/>
                                </div>
                            </div>
                            <div
                                className="borders-block__hr--horizontal borders-block__hr--mobile"/>
                        </div>
                        <div className="borders-block__hr"/>
                        <div className="borders-block__col-right">
                            <div className="borders-block__col--block">
                                <div className="borders-block__col--number">4
                                </div>
                                <div
                                    className="borders-block__col--big-text borders-block__col--big-text--2">
                                    <div
                                        dangerouslySetInnerHTML={{__html: t("supportBothPointAndAreaEmissionSources")}}/>
                                </div>
                            </div>
                            <div className="borders-block__hr--horizontal"/>
                            <div className="borders-block__col--block">
                                <div className="borders-block__col--number">5
                                </div>
                                <div className="borders-block__col--big-text borders-block__col--big-text--2">
                                    <div
                                        dangerouslySetInnerHTML={{__html: t("historicalDataBackupForAnyFutureRequests")}}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="wrapper_block wrapper_block__block borders-block software__img_2--wrapper wrapper_block__block--no-border wtu__img--wrapper">
                    <div>
                        <video loop autoPlay muted playsInline
                               className="wtu__img"
                               ref={videoSoftwareRef}
                               poster={PlumePoster}>
                            <source src={PlumeModeling} type="video/mp4"/>
                        </video>
                    </div>
                </div>

                <div
                    className="wrapper_block wrapper_block__block wrapper_block__big_padding_top mt-50 pt-80 wrapper_block__block--top-padding wrapper_block__block--no-border background_yellow pb-50">
                    <div className="borders-block__col modeling-block__header">
                        <div className="title-and-img__title">
                            <div className="title-and-img__rectangle_blue"/>
                            <div dangerouslySetInnerHTML={{__html: t("cityScreenForecast")}}/>
                        </div>
                        <div className="title-and-img__description modeling-block__header-description">
                            <div className="title-and-img__description--2 title-and-img__description--3 borders-block__col--point"/>
                            <a href="/platform/">{t("cityAirPlatform")}</a>
                            {t("cityAirModuleAnd")}
                        </div>
                        {/*<div className="main-block__btn-wrapper">
                            <a className="btn btn-modal btn_bg-purple" href="#contacts">
                                {t("requestDemo")}
                            </a>
                        </div>*/}
                    </div>
                </div>
                <div className="wrapper_block  wrapper_block__block wrapper_block__block--no-border borders-block background_yellow">
                    <div className="borders-block__col">
                        <div className="borders-block__col-left">
                            <div className="borders-block__col--block">
                                <div className="borders-block__col--number">1
                                </div>
                                <div
                                    className="borders-block__col--big-text borders-block__col--big-text--2">
                                    <div
                                        dangerouslySetInnerHTML={{__html: t("realTimeContinuousAir")}}/>
                                </div>
                            </div>
                            <div
                                className="borders-block__hr--horizontal borders-block__hr--mobile"/>
                        </div>
                        <div className="borders-block__hr"/>
                        <div className="borders-block__col-right">
                            <div className="borders-block__col--block">
                                <div className="borders-block__col--number">2
                                </div>
                                <div
                                    className="borders-block__col--big-text borders-block__col--big-text--2">
                                    <div
                                        dangerouslySetInnerHTML={{__html: t("controlPointsMapping")}}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="wrapper_block wrapper_block__block software__img_2--wrapper wrapper_block__block--no-border wtu__img--wrapper background_yellow borders-block pb-50">
                    <div>
                        <video loop autoPlay muted playsInline
                               className="wtu__img"
                               ref={videoSoftwareRef}
                               poster={ForecastPoster}>
                            <source src={ForecastModeling} type="video/mp4"/>
                        </video>
                    </div>
                </div>
                <div className="wrapper_block borders-block wrapper_block__block wrapper_block__big_padding_top wrapper_block__block--top-padding wrapper_block__block--no-border modeling-block mt-50">
                    <div className="borders-block__col borders-block__col--2 modeling-block__header">
                        <div className="title-and-img__title">
                            <div className="title-and-img__rectangle"/>
                            <div dangerouslySetInnerHTML={{__html: t("cityScreenLens")}}/>
                        </div>
                        <div className="title-and-img__description modeling-block__header-description">
                            <div className="title-and-img__description--2 title-and-img__description--3 borders-block__col--point"
                                 dangerouslySetInnerHTML={{__html: t("cityScreenForecastModuleExtensionFor")}}/>
                        </div>
                        {/*<div className="main-block__btn-wrapper">
                            <a className="btn btn-modal btn_bg-purple" href="#contacts">
                                {t("requestDemo")}
                            </a>
                        </div>*/}
                    </div>
                </div>
                <div className="wrapper_block--gray padding-top-mobile-25" />
                <div className="wrapper_block wrapper_block__block_align-right wrapper_block__block--top-padding wrapper_block__block--no-padding-bottom background_yellow title-and-img-plumes title-and-img--small-padding-2 no-border">
                    <div className="title-and-img__title title-and-img__title--big_width">
                        <div className="title-and-img__title--flex font-size-3-5-rem"><span dangerouslySetInnerHTML={{__html: t("cityAirExpertiseIsBased")}} /></div>
                    </div>
                    <div className="title-and-img__img"><img alt="" src={Rectangle}/>
                    </div>
                </div>
                <div
                    className="wrapper_block wrapper_block__block background_yellow wrapper_block--gray wrapper_block__block--no-border two-col two-col--non-padding wrapper_block--padding-bottom not-border-top-ipad">
                    <div className="two-col__col two-col__col--2">
                        <div className="two-col__col-left two-col__col-left--non_border">
                            <ul className="two-col__ul">
                                <li><div className="two-col__section">
                                    <div className="two-col__title"><span dangerouslySetInnerHTML={{__html: t("identificationOfUnknownPollution")}} /></div>
                                </div></li>
                                <li><div className="two-col__section">
                                    <div className="two-col__title"><span dangerouslySetInnerHTML={{__html: t("identificationOfInfluence")}} /></div>
                                </div></li>
                            </ul>
                        </div>
                        <div className="two-col__col-right">
                            <ul className="two-col__ul">
                            <li><div className="two-col__section">
                                <div className="two-col__title"><span dangerouslySetInnerHTML={{__html: t("modellingOfPossible")}} /></div>
                            </div></li>
                            <li><div className="two-col__section">
                                <div className="two-col__title"><span dangerouslySetInnerHTML={{__html: t("BestOptimalApproach")}} /></div>
                            </div></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="link_and__end-block wrapper_block--padding-bottom wrapper_block wrapper_block__block wrapper_block__block--no-border" id="contacts">
                    <span className="link_and__mail"
                          dangerouslySetInnerHTML={{__html: t("ToGetMoreInformation")}}/>
                </div>
            </div>
        </div>
    )
}

ModelingComponent.propTypes = {}

ModelingComponent.defaultProps = {}

export default ModelingComponent
